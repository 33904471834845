<template>
  <!-- qr-code START -->
  <div class="qr-code">

    <!-- qr-code:icon START -->
    <div class="icon">
      <img width="64" src="@/assets/images/icon-qrcode-scan@3x.png" />
    </div>
    <!-- qr-code:icon END -->

    <h1 v-html="$t('qrcode.scanqrcode')"></h1>

    <!-- qr-code:description START -->
    <div class="description">
      <p v-html="$t('terminal.smartphone.code')"></p>
    </div>
    <!-- qr-code:description START -->

    <!-- qr-code:code START -->
    <div class="code">
        <img v-if="iconCode" :src="iconCode" />
        <span
          class="f-like-image-loading"
          v-if="!iconCode"
        ></span>
    </div>
    <!-- qr-code:code END -->

  </div>
  <!-- qr-code END -->
</template>

<script>
export default {
  name: 'qr-code',
  /**
   * ROUTE WATCH
   * Check if prev route was "scan"
   * If was disable getBox function from executing
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const t = vm;
      t.prevRoute = from;
    });
  },
  data() {
    return {
      qrcode: '',
      boxFetched: null,
      prevRoute: null,
    };
  },
  async created() {
    this.qrcode = await this.FETCH_QRCODE();

    // clear cart
    // if (this.$store.getters.storeCart.items !== 'undefined') {
    //   this.$store.dispatch('clearCart');
    // }

    this.getBox();
  },
  computed: {
    iconCode() {
      return `${this.qrcode}`;
    },
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    async FETCH_QRCODE() {
      await this.$store.dispatch('setQrcode');
      return this.$store.getters.qrcode;
    },

    async getBox() {
      const t = this;

      await this.FETCH_SETTINGS().then((settings) => {
        if (t.boxFetched === null && (t.prevRoute && t.prevRoute.name !== 'scan') && settings) {
          const timeoutHasEnteredBox = settings.app.timeouts.hasEnteredBox;
          t.boxFetched = setInterval((function getBoxInit() {
            // select user/box
            t.$http.get(`${process.env.VUE_APP_BASE_URL}user/hasEnteredBox`, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data && response.data.hasEnteredBox === true) {
                  localStorage.setItem('locationID', response.data.box.id);
                  t.$store.commit('SET_LOCATION_ID', response.data.box.id);
                  if (t.$route.path !== '/scan') {
                    sessionStorage.setItem('activateNav', true);
                    t.$router.push('/scan');
                  }
                } else {
                  sessionStorage.setItem('activateNav', false);
                }
              })
              .catch((error) => {
                if (!error.response) {
                  clearInterval(t.boxFetched);
                  // network error
                  t.errorStatus = 'Error: Network Error';
                } else {
                  t.errorStatus = error.response.data;
                }
              });
            return getBoxInit;
          }()), timeoutHasEnteredBox);
        }
      });
    },
  },
  mounted() {
    // close $notify from Payback Screen
    this.$notify.closeAll();
  },
  beforeDestroy() {
    clearInterval(this.boxFetched);
  },
};
</script>
